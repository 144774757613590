.content-preview .ant-drawer-body{
  background: #F2F4F5;
}
/* .content-bg{
  background: white;
  border:solid 1px #E4E6E8;
  border-radius: 10px;
}
.content-bg .text{
  padding: 10px;
}
.feedCard-frist{
  height: 160px;
  position: relative;
  width: 100%;
}
.feedCard-frist .img{
  z-index: 1;
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.feedCard-frist .mask{
  overflow: auto;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: .5;
  position: absolute;
  background: linear-gradient(#eeeeee, #000000);
}

.notlast{
  border-bottom: solid 1px #E4E6E8;
}

.islast{
  border-radius: 10px;
}

.feedCard-frist .title{
  color: white;
  z-index: 3;
  left: 10px;
  bottom: 10px;
  position: absolute;
  font-size: 15px;
  max-height: calc(50% - 10px);
  overflow: hidden;
}
.feedCard-second{
  height: 70px;
  background: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.feedCard-second .title{
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-right: 10px;
}
.feedCard-second .img{
  min-width: 50px;
  height: 50px;
}
.feedCard-second .img img{
  height: 100%;
  width: 100%;
  object-fit: cover;
} */