.preview-feedcard-bg{
  background: white;
  border: solid 1px #E4E6E8;
  border-radius: 10px;
  width: 360px;
  min-width: 360px;
}
.preview-feedcard-bg .text{
  padding: 10px;
}

.preview-feedcard-bg .feedCard-frist{
  height: 160px;
  position: relative;
  width: 100%;
}
.preview-feedcard-bg .feedCard-frist .title{
  color: white;
  z-index: 3;
  left: 10px;
  bottom: 10px;
  position: absolute;
  font-size: 15px;
  max-height: calc(50% - 10px);
  overflow: hidden;
}
.preview-feedcard-bg .feedCard-frist .img{
  overflow: hidden;
  z-index: 1;
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.preview-feedcard-bg .feedCard-frist .mask{
  overflow: auto;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: .5;
  position: absolute;
  background: linear-gradient(#eeeeee, #000000);
}

.preview-feedcard-bg .preview-cnum:last-child{
  border-radius: 10px;
}
.preview-feedcard-bg .not-frist {
  padding: 0 10px;
}
.preview-feedcard-bg .not-frist .feedCard-second:not(:last-child){
  border-bottom: solid 1px #E4E6E8;
}

.preview-feedcard-bg .not-frist .feedCard-second{
  height: 70px;
  background: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.preview-feedcard-bg .not-frist .feedCard-second .title{
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-right: 10px;
}
.preview-feedcard-bg .not-frist .feedCard-second .img{
  overflow: hidden;
  min-width: 50px;
  width: 50px;
  min-height: 50px;
  height: 50px;
}
.preview-feedcard-bg .not-frist .feedCard-second .img img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}