#app{
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.fullspin{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

:root {
  --primary-bg-color: #f8fbff;
  --secondary-bg-color: #f5f5f5;
  --primary-color: #3daef9;
  --secondary-color: #f0fcff;
  --link-color: #31a1dc;
  --link-hover-color: #5ab4e3;
  --link-active-color: #2f99d1;
  --btn-color: #3daef9;
  --btn-hover-color: #64befa;
  --btn-active-color: #3aa5ed;
  --primary-box-shadow: 0 0 0 2px rgba(61,174,249,0.2);
  --col-divider-color: #dee7f0;
  --topnav-bg-color: #dee7f0;
  --topnav-input-color: #9aa6b3;
  --topnav-icon-color: #14243a;
  --xtabs-tabbar-bg-color: #cddbe8;
  --xtabs-tabbar-active-bg-color: #f1f6fd;
  --xtabs-tabbar-icon-color: #536072;
  --xtabs-tabbar-icon-active-color: #536072;
  --sidebar-bg-color: linear-gradient(180deg,#1eb4f8,#008cef);
  --sidebar-bg-image: linear-gradient(180deg,#1eb4f8,#008cef);
  --sidebar-badge-bg-color: #ff4c39;
  --sidebar-icon-color: #fff;
  --sidebar-icon-opacity: 0.5;
  --sidebar-icon-active-bg: hsla(0,0%,100%,0.32);
  --sidebar-icon-active-color: #fff;
  --sidemenu-bg-color: #f1f6fd;
  --sidemenu-bg-image: none;
  --sidemenu-item-hover-bg-color: #ddedf9;
  --sidemenu-item-active-bg-color: #c9e6fb;
  --sidemenu-item-border-right-color: #3daef9;
  /* --boot-bg-image: url(https\:\/\/img\.alicdn\.com\/tfs\/TB1KqP2XNGYBuNjy0FnXXX5lpXa-200-200\.png); */
  --boot-text-zh: "尝试逗他人开心，每天让至少三个人因你而微笑。";
  --boot-text-en: "Try to make at least three people smile each day.";
  --phone-bar-bg-color: #2a3148;
  /* --phone-status-onduty: url(\/\/img\.alicdn\.com\/tfs\/TB1LR7kEkzoK1RjSZFlXXai4VXa-160-160\.gif);
  --phone-status-rest: url(\/\/img\.alicdn\.com\/tfs\/TB1UKIqvmzqK1RjSZFjXXblCFXa-160-160\.gif); */
  --fangcun-secondary-bg-color: #e9eff2;
  --biztabs-tabbar-active-color: #14243a;
  --biztabs-tabbar-font-size: 14px;
  --biztabs-tabbar-active-font-size: 20px;
  --biztabs-tabbar-active-ink-height: 4px;
  --im-boot-bg-image: var(--boot-bg-image);
  --msg-a-bg: #fff;
  --msg-a-border: 1px solid #e1e0e4;
  --msg-a-border-radius: 18px 18px 18px 4px;
  --msg-a-color: #14243a;
  --msg-a-font-size: 14px;
  --msg-b-bg: #3daef9;
  --msg-b-border: none;
  --msg-b-border-radius: 18px 18px 4px 18px;
  --msg-b-color: #fff;
  --msg-b-font-size: 14px;
  /* --mr-ill-img-error-small: url(\/\/img\.alicdn\.com\/tfs\/TB1\.a7aygHqK1RjSZFgXXa7JXXa-100-100\.png);
  --mr-ill-img-blank-small: url(\/\/img\.alicdn\.com\/tfs\/TB1JSMXybvpK1RjSZPiXXbmwXXa-100-100\.png);
  --mr-ill-img-error: url(\/\/img\.alicdn\.com\/tfs\/TB1jYMuvcfpK1RjSZFOXXa6nFXa-200-200\.png);
  --mr-ill-img-blank: url(\/\/img\.alicdn\.com\/tfs\/TB1wlMsvkPoK1RjSZKbXXX1IXXa-200-200\.png);
  --mr-ill-img-maintenance-large: url(\/\/img\.alicdn\.com\/tfs\/TB1w2ULvhnaK1RjSZFBXXcW7VXa-250-250\.png);
  --mr-ill-img-no-access-large: url(\/\/img\.alicdn\.com\/tfs\/TB1kqMmvmzqK1RjSZPxXXc4tVXa-250-250\.png);
  --mr-ill-img-error-large: url(\/\/img\.alicdn\.com\/tfs\/TB1S\.QovmzqK1RjSZFjXXblCFXa-250-250\.png);
  --mr-ill-img-blank-large: url(\/\/img\.alicdn\.com\/tfs\/TB1MgMqvbvpK1RjSZFqXXcXUVXa-250-250\.png); */
  --home-bg-image: linear-gradient(to right top,#1eb4f8,#008cef);
}