.regist-main{
  height: 100%;
}
.Header{
  height: 60px;
}
.content{
  height: calc(100% - 60px);
  background: url("https://static.dingtalk.com/media/lALPDgtYvD9Q93nNBDjNB4A_1920_1080.png") no-repeat ;
  background-size:100%;
  padding-top: 50px;
}
.content .card{
  background: #FFFFFF;
  -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 800px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  padding: 25px 50px;
}
.content .card .title{
  font-family: PingFangSC-Medium;
  font-size: 28px;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
  margin: 0px auto 25px;
}
.content .card .steps-content{
  height: 100%;
  margin-top: 34px;
  min-height: 336px;
}


.content .card .steps-content .regist-phone{
  margin: 0 auto;
  width: 300px;
}

/* .content .card .steps-content .regist-phone .ant-form-item{
  margin: 0 ;
} */

.content .card .steps-action{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.content .card .steps-action .ant-btn{
  width: 100%;
  margin: 0 20px;
}

#submit{
  width: 100%;
}