.table-view{
  background: #fff;
  border-radius: 4px;
  padding: 16px 24px;
}
.table-head{
  display: flex;
  flex-direction: row-reverse;
}
.table-data{
  margin-top: 20px;
}