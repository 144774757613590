.robot-add{
  position: relative;
  padding: 16px 16px 200px 16px;
}
.robot-add .add-view{
  background: #fff;
  border-radius: 4px;
  padding: 16px 24px;
}
.view-header{
  width: 700px;
  margin:0 auto;
}
.input-view{
  display: flex;
  justify-content: space-between;
}
.input-view .info,
.input-view .help-info
{
  margin: 0 10px;
  width: 100%;
}
.preview-feedCard .ant-card-body{
  background: #F2F4F5;
}
.view-content{
  margin: 50px 0;
}