.lazayoa-login{
  height: 100%;
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.lazayoa-login .copyright{
  position: absolute;
  bottom: 40px;
  /* z-index: 13000; */
  text-align: center;
  color: #D8D8D8;
  width: 100%;
  user-select: none;
  cursor: default;
  font-size: 12px;
}

/* .login-form {
  max-width: 300px;
  margin:  0 auto;
} */
.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}