.dashboard-header{
  display: flex;
  /* justify-content: space-between; */
}

.dashboard-header .userContent{
  display: flex;
}
.dashboard-header .robot-info{
  margin-left: 100px;
}
.dashboard-header .userContent .info-view{
  margin-left: 24px;
  color: rgba(0,0,0,.45);
  line-height: 22px;

}
.dashboard-header .userContent .ask{
  margin-bottom: 12px;
  color: rgba(0,0,0,.85);
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}
.dashboard-header .userContent .role{}

.dashboard-header .content-view{
  background: #fff;
  height: 100%;
  border-radius: 4px;
  padding: 16px 24px;
}

