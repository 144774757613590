.content-add{
  position: relative;
  padding: 16px 16px 200px 16px;
}
.content-add .add-view{
  background: #fff;
  border-radius: 4px;
  padding: 16px 24px;
}
.content-add .list{
  padding: 10px 0;
}
.content-add .list .ant-card-body{
  text-align: center;
}