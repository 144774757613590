.avatar-content{
  min-width: 200px;
}
.avatar-content .username{
  font-size: 14px;
}
.avatar-content .info-label{
  display: flex;
  /* justify-content: space-between; */
}
.avatar-content .info-label label{
  width: 100px;
  color: #999;
}
.avatar-menu{
  border: none;
}
.avatar-menu .ant-menu-item{
  font-size: 14px;
}