
/* #lazy-system .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#lazy-system .trigger:hover {
  color: #1890ff;
}

#lazy-system .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

#lazy-system .site-layout .site-layout-background {
  background: #fff;
} */
.system-container{
  height: 100%;
}
.nav-sider{
  background: var(--sidebar-bg-color);
}
.system-container .left-nav{
  background-color: linear-gradient(180deg,#1eb4f8,#008cef);
  width: 48px;
  background: transparent;
}
.system-container .left-nav .logo{
  width: 48px;
  height: 48px;
}
.system-container .left-nav .ant-menu-item{
  height: 48px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.system-container .left-nav .ant-menu-item.ant-menu-item-selected{
  color: var(--sidebar-icon-active-color);
  background-color: var(--sidebar-icon-active-bg);
}
.system-container .header-nav{
  height: 48px;
  background-color: var(--topnav-bg-color);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0 20px;
}
.system-container .content-container{
  height: 100vh;
  overflow: auto;
  background-color: #F5F5F5;;
}
.logo{
  display: flex;
  justify-content: center;
  align-items: center;
}