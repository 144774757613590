.robot-index{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.robot-index .robot-nav{
  overflow: hidden;
  min-width: 240px;
  width: 240px;
  background: var(--sidemenu-bg-color);
  border-right: 1px solid #ddd;
}
.robot-index .robot-nav .robot-title{
  font-size: 16px;
  padding: 12px 12px 12px 22px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}
.robot-index .robot-nav .robot-menu{
  height: 100%;
  background: transparent;
}
.robot-index .robot-nav .robot-menu ul{
  background: transparent;
}
.robot-index .robot-content{
  height: 100%;
  width: 100%;
  position: relative;
  padding-top: 48px;
}
.robot-index .robot-content .content-title{
  font-size: 16px;
  padding: 12px 22px;
  font-weight: bold;
  background: #fff;
  line-height: 22px;
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}
.robot-index .robot-content .content-view{
  height: 100%;
  overflow: auto;
  position: relative;
  padding: 16px 16px 200px 16px;
}